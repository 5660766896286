<template>
  <div>
    <h3>Chấm bài tự luận</h3>
    <hr>
    <div class="p-8 rounded-lg d-flex" style="background: #f9f7fb; align-items: center;">
      <div class="row w-100">
        <div class="d-flex col-md-4">
          <span class="font-weight-bold" style="min-width: 100px">Học viên: </span>
          <b>{{ mark_essay?.student?.name }}</b>
        </div>
        <div class="d-flex col-md-4 my-3 my-md-0">
          <span class="font-weight-bold" style="min-width: 100px">Số điện thoại:</span>
          <b>{{ mark_essay?.student?.phone }}</b>
        </div>
        <div class="d-flex col-md-4 mb-3 mb-md-0">
          <span class="font-weight-bold" style="min-width: 100px">Buổi học:</span>
          <b>{{ lesson }}</b>
        </div>
      </div>
    </div>
    <div class="rounded-lg mt-5">
      <CommentAnswer v-if="answerOfStudent.length > 0 && !isCambridgeMentor()"
                     :group-questions="answerOfStudent"
                     :typeMark="score_max_homework"
                     :course_student_id="course_student_id"
      />
      <comment-answer-kid
          v-if="answerOfStudent.length > 0 && isCambridgeMentor()"
          :group-questions="answerOfStudent"
          :typeMark="score_max_homework"
          :course_student_id="course_student_id"
      >
      </comment-answer-kid>

    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment-timezone";
import CommentAnswer from "./components/CommentAnswer.vue";

import {ADMIN_GET_MARK_HOMEWORK, ADMIN_HOMEWORK} from "@/core/services/store/classes/classes.module";
import {AnswerStudent} from "@/views/pages/classrooms/model/AnswerStudent";
import {CAMBRIDGE_MENTOR_ID} from "../../../core/option/centerEDUTALK";
import CommentAnswerKid from "./components/CommentAnswerKid.vue";

export default {
  components: {CommentAnswerKid, CommentAnswer},
  data() {
    return {
      mark_essay: {
        isLoadingMarkEssay: true,
        student: {},
        data: [],
      },
      lesson: null,
      classroom_id: null,
      student_id: null,
      course_student_id: null,
      time_send_homework: null,
      loading: false,
      score_max_homework: 0,
      answerOfStudent: []
    }
  },
  async mounted() {
    let view_result = this.$route.query.type;
    this.lesson = this.$route.params.id;
    if (view_result) {
      this.typeView = "mark-homework";
      this.lesson = this.$route.query.lesson;
    }
    this.classroom_id = this.$route.query.classroom_id
    this.student_id = this.$route.query.student_id
    await this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý lớp học", route: 'schedule-class', id: this.$route.query.classroom_id},
      {title: "Chấm điểm bài tập về nhà"}
    ]);
    await this.getMarkStudent()
  },
  methods: {
    getMarkStudent() {
      this.isloadingHomework = true;
      this.$store.dispatch(ADMIN_HOMEWORK, {
        classroom_id: this.classroom_id,
        lesson: this.lesson,
        type: 'homework',
        user_id: this.student_id
      }).then((res) => {
        if (!res.error) {
          if (res.data.length > 0) {
            this.mark_essay.student = res.data[0];
            this.score_max_homework = res.data[0].score_max_homework
            this.course_student_id = res.data[0].course_student_id
            let payload = {
              classroom_id: this.classroom_id,
              course_student_id: res.data[0].course_student_id,
              overall_id: res.data[0].test.overall_id
            }
            this.$store.dispatch(ADMIN_GET_MARK_HOMEWORK, payload).then((res) => {
              if (!res.error) {
                this.mark_essay.data = res.data;
                this.answerOfStudent = this.mark_essay?.data.map((e) => {
                  let typeAnswer = e?.group_question?.type
                  return new AnswerStudent(e.comment, e.comments, e.group_question, e.outcomes_id, e.overall_result_id, e.score, e.score_detail, e.status_mark, e.student_answer, e.test_id, e.type, e.user_id, typeAnswer)
                })
                this.mark_essay.isLoadingMarkEssay = false;
              }
            }).catch((e) => {
              console.log(e, 'error')
            });
            this.time_send_homework = moment(this.mark_essay.student.test.created_at).format('HH:mm:ss YYYY-MM-DD')
            this.isLoadingMarkEssay = false;
          }
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    isCambridgeMentor() {
      const idCenter = this.$route.query.center_id;
      return +idCenter === CAMBRIDGE_MENTOR_ID
    }
  },

}
</script>

<style lang="scss" scoped>
.btn-custom {
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  align-self: center;

  :hover {
    background-color: burlywood;
  }
}
</style>