<template>
  <div class="card mt-3" style="height: fit-content;width: 100%;">
    <div class="card w-100 h-100" style="background-color: #edeaf2">
      <div class="d-flex w-100 h-100 py-2 " >
        <div style="width: 20% ; height: 100%" class="d-flex align-items-center ">
          <p class="font-weight-boldest ml-5" style="margin-bottom: 0; font-size: 13px">{{label}}</p>
        </div>
        <div class="d-flex  " style="width: 100%;">
          <ValidationProvider
              name="Nhận xét"
              :rules="{required :!checkException(),min:checkException()?0: 50}"
              v-slot="{ errors }"
              :vid="`nhanXet${label}`"
              class="w-100 px-4"
          >
            <el-input  placeholder="Nhập nhận xét" v-model="comment" style="width: 100%" @change="handleData()" ></el-input>
            <div class="fv-plugins-message-container">
              <div
                  data-field="name"
                  data-validator="notEmpty"
                  class="fv-help-block"
              >
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "SelectOptionKid",
  data() {
    return {
       comment: '',
      exceptSkills: ['Sáng tạo','Ngữ Pháp','Ngữ điệu']
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    markValue: {
      type: Object,
    },
    placeholder: {
      type: String,
      default: ''
    },
    eName: {
      type: String
    }
  },
  methods:{
    handleData(){
     this.markValue[this.eName] = this.comment
    },
    checkException(){
     return  this.exceptSkills.includes(this.label)
    }
  }
}
</script>
<style scoped>

</style>
