<template>
  <div>
    <div v-if="typeQuestion === 'write_paragraph'" class="border border-dark rounded-lg mb-5 p-8 bg-white">
      <p class="font-weight-bold text-break m-0 p-2">
        {{ groupQuestions[indexQuestion]?.group_question?.description }}
      </p>
      <p class="m-0" style="white-space: pre-line">
        {{ groupQuestions[indexQuestion]?.group_question?.script }}
      </p>
      <img :src="groupQuestions[indexQuestion].group_question.image" alt="">
    </div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="form" @submit.prevent autocomplete="off">
        <div class="p-5 rounded-lg border  bg-white">
          <div style="display: flex; justify-content: end">
            <div style="display: flex; align-items: center; gap: 4px;" class="mb-3">
              <el-button class="p-2" v-if="indexQuestion > 0"
                         style="background-color: #bab9bc; color: #000;font-size: 13px; " @click="prevQuestion">Câu
                trước
              </el-button>
              <el-button class="p-2" style="background-color: #554B7E; color: #f9f7fb;font-size: 13px"
                         @click="handleSubmit(nextQuestion)">
                {{ groupQuestions.length !== indexQuestion + 1 ? "Câu sau" : "Lưu" }}
              </el-button>
            </div>
          </div>
          <div
              v-if="typeQuestion === 'write_paragraph'"
              style="width: 100%; display: flex; align-items: center; justify-content: space-between; padding:0 14px 20px 14px;">
            <div class="d-flex align-self-center">
              <span style="font-weight: 600;">Chấm điểm</span>
            </div>
          </div>
          <div class="d-flex">
            <div
                class="d-flex flex-column col-6"
                style="gap: 10px"
            >
              <div v-if="typeQuestion === 'record_video'" class=" rounded-sm p-4 text-white"
                   style="background-color: #554b7e">
                Record_video
              </div>
              <div v-if="typeQuestion === 'record_audio'" class=" rounded-sm p-4 text-white"
                   style="background-color: #554b7e">
                Record_audio
              </div>
              <div v-if="typeQuestion === 'record_video' || typeQuestion === 'record_audio'"
                   class="rounded-lg p-5 font-weight-bolder border border-dark" style="background: #f9f7fb">
                <p class="font-weight-bold text-break m-0">
                  {{ groupQuestions[indexQuestion]?.group_question?.description }}
                </p>
                <p class="m-0" style="white-space: pre-line">
                  {{ groupQuestions[indexQuestion]?.group_question?.script }}
                </p>
                <el-image
                    :src="groupQuestions[indexQuestion]?.group_question?.image"
                    fit="fill"
                    v-if="groupQuestions[indexQuestion]?.group_question?.image"
                ></el-image>
                <div
                    v-if="groupQuestions[indexQuestion]?.group_question?.video !== ''"
                    class="rounded-lg overflow-hidden w-full"
                >
                  <video class="rounded" width="320" height="220" controls preload="auto">
                    <source :src="groupQuestions[indexQuestion]?.group_question?.video" type="video/mp4"/>
                  </video>
                </div>
                <div
                    v-if="groupQuestions[indexQuestion]?.group_question?.audio !== ''"
                    class="rounded-lg overflow-hidden w-full"
                >
                  <audio class="rounded" controls preload="auto">
                    <source :src="groupQuestions[indexQuestion]?.group_question?.audio" type="audio/ogg">
                    <source :src="groupQuestions[indexQuestion]?.group_question?.audio" type="audio/mpeg">
                  </audio>
                </div>
              </div>
              <div class="p-5 rounded-lg border border-dark d-flex flex-column" style="background-color: #f9f7fb;">
                <span class="font-weight-bolder flex-fill">Bài làm</span>
                <div
                    v-if="typeQuestion === 'write_paragraph'"
                    class="bg-white p-4 my-5 border border-dark rounded-lg"
                >
                 <p>{{currentQuestion.student_answer}}</p>
                </div>
                <div v-if="typeQuestion === 'record_video'">
                  <video class="rounded" width="320" height="220" controls preload="auto">
                    <source :src="groupQuestions[indexQuestion]?.student_answer" type="video/mp4"/>
                    <source :src="groupQuestions[indexQuestion]?.student_answer" type="video/ogg">
                  </video>
                </div>
                <div v-if="typeQuestion === 'record_audio'">
                  <audio class="rounded" controls :key="indexQuestion">
                    <source :src="groupQuestions[indexQuestion]?.student_answer" type="audio/ogg"/>
                  </audio>
                </div>
              </div>
            </div>
            <div v-if="typeQuestion === 'record_video' || typeQuestion === 'record_audio' || typeQuestion === 'write_paragraph'" class="card col-6 p-8"
                 style="gap: 20px; background-color: #f6f4f8">
              <select-option-kid
                  v-for="(skill,index) in criteriaQuestion"
                  class-name="align-items-center "
                  :label="skill.vName"
                  :eName="skill.eName"
                  placeholder="Chọn thang điểm"
                  :markValue="currentQuestion.skill"
                  :key="`${indexQuestion}.${index}`"
              ></select-option-kid>
              <div class="d-flex">
                <div style="line-height: 2">
                  <p class="font-weight-boldest" style="font-size: 16px">Điểm toàn bài</p>
                </div>
                <div class="ml-5" :key="indexQuestion">
                  <ValidationProvider
                      name="Điểm toàn bài"
                      rules="required"
                      v-slot="{ errors,  }"
                      class="w-100 px-4"
                  >
                    <el-select v-model="groupQuestions[indexQuestion].totalScore" placeholder="Chọn điểm"
                               style="width: 100%" size="small" :key="indexQuestion">
                      <el-option
                          v-for="item in selectScore"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div
                          data-field="name"
                          data-validator="notEmpty"
                          class="fv-help-block"
                      >
                        {{ errors[0] }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>

import {selectScore, selectScore9, WRITE_PARAGRAPH} from "@/core/option/selectScore";

import router from "@/router";
import {ADMIN_POST_MARK_HOMEWORK} from "@/core/services/store/classes/classes.module";
import SelectOptionKid from "./SelectOptionKid.vue";
import {CRITERIA_OF_RECORD_VIDEO, KIND_OF_CRITERIA} from "../../../../core/option/kidScore";
import {ADMIN_MARK_HOMEWORK_KID} from "../../../../core/services/store/classes/classes.module";

export default {
  data() {
    return {
      indexQuestion: 0,
      feedback: "",
      editComment: false,
      indexCommentEdit: null,
      loading: false,
      textComment: "",
      indexTextComment: null,
      disableAddComment: false,
      disableSubmit: false,
      commentToAnswer: [],
      result: [],
      confirmMarkHomework: false,
      addComment: false,
      enableHighlight: false,
      totalScore: null,
      answerHeight: 0,
      achievement: 0,
      lexical: 0,
      coherence: 0,
      grammatical: 0,
      overall: 0,
      fluency: {
        valueOption: null,
        comment: '',
      },
      coherenceSpeaking: {
        valueOption: null,
        comment: '',
      },
      grammaticalRange: {
        valueOption: null,
        comment: '',
      },
      grammaticalAccuracy: {
        valueOption: null,
        comment: '',
      },
      pronunciation: {
        valueOption: null,
        comment: '',
      },
      vocabularyRange: {
        valueOption: null,
        comment: '',
      },
      vocabularyAccuracy: {
        valueOption: null,
        comment: '',
      },
      WRITE_PARAGRAPH: WRITE_PARAGRAPH,
      skills: []
    }
  },
  components: {SelectOptionKid},
  props: {
    groupQuestions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    typeMark: {
      type: Number,
      default: 0
    },
    course_student_id: {
      type: Number,
      default: null,
    }
  },
  mounted() {
    console.log(this.groupQuestions)
    this.createCriteriaQuestion()
  },
  computed: {
    selectScore() {
      return this.typeMark === 10 ? selectScore : selectScore9
    },
    typeQuestion() {
      return this.groupQuestions[this.indexQuestion]?.group_question?.type
    },
    currentQuestion() {
      return this.groupQuestions[this.indexQuestion]
    },
    criteriaQuestion(){
      return KIND_OF_CRITERIA.get(this.typeAnswer)
    },
    typeAnswer(){
      return this.groupQuestions[this.indexQuestion]?.typeAnswer
    }
  },
  methods: {
    prevQuestion() {
      this.indexQuestion -= 1
      this.commentToAnswer = this.currentQuestion.commentToAnswer
    },
    nextQuestion() {
      if (this.indexQuestion + 1 !== this.groupQuestions.length) {
        this.indexQuestion += 1
        this.commentToAnswer = this.currentQuestion?.commentToAnswer
      } else {
        this.summit()
        this.disableSubmit = true
      }
    },
    summit() {
      this.loading = true
      let results = this.groupQuestions.map((question) => {
        return {
          score: question.totalScore,
          outcomes_id: question?.outcomes_id,
          comments: Object.entries(question.skill).map(([key, value]) => ({ key: key, value }))
        }
      })
      this.$store
          .dispatch(ADMIN_MARK_HOMEWORK_KID, {
            classroom_id: this.$route.query.classroom_id,
            course_student_id: this.course_student_id,
            overall_result_id: this.$route.query.overall_id,
            type: "homework",
            results: results,
          })
          .then(() => {
            this.$message({
              type: "success",
              message: "Chấm điểm thành công!",
              showClose: true,
            });
            this.$router.push({
              name: "list_mark_homework", params: {id: this.$route.params.id},
              query: {
                classroom_id: this.$route.query.classroom_id,
                lesson: this.$route.query.lesson,
                learning_schedule_id: this.$route.query.learning_schedule_id,
                center_id: this.$route.query.center_id
              }
            });
          })
          .catch((res) => {
            this.$message({
              type: "error",
              message: "Sever đang bận! " + res.data.message,
              showClose: true,
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    createCriteriaQuestion(){

      // thêm các tiêu chí vào từng loại câu hỏi, để show các tiêu chí để chấm
      this.groupQuestions.map((question)=>{
        const criteriaOfQuestion = KIND_OF_CRITERIA.get(question.typeAnswer)
        let skill = {}
        criteriaOfQuestion.forEach((criteria)=>{
          skill = {...skill,[criteria.eName]: ''}
        })
        console.log(question)
       question.skill = skill
      })

    }
  }
}
</script>

<style lang="scss" scoped>
</style>
