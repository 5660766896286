import { render, staticRenderFns } from "./MarkHomework.vue?vue&type=template&id=42e4af40&scoped=true"
import script from "./MarkHomework.vue?vue&type=script&lang=js"
export * from "./MarkHomework.vue?vue&type=script&lang=js"
import style0 from "./MarkHomework.vue?vue&type=style&index=0&id=42e4af40&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42e4af40",
  null
  
)

export default component.exports